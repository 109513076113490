// import "./bootstrap";

import Swiper from "swiper";
import { Autoplay, EffectCoverflow } from "swiper/modules";

import "swiper/css";

// Customer logos
const customerLogosSwiper = new Swiper(".swiper-customer-logos", {
    loop: true,
    speed: 3000,
    spaceBetween: 100,
    centeredSlides: true,
    autoplay: {
        delay: 300,
        disableOnInteraction: false,
    },
    slidesPerView: 6,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 6,
            spaceBetween: 40,
        },
    },
    modules: [Autoplay],
});

const projectsSwiper = new Swiper(".swiper-projects", {
    loop: true,
    speed: 3000,
    spaceBetween: 100,
    centeredSlides: true,
    autoplay: {
        delay: 300,
        disableOnInteraction: false,
    },
    slidesPerView: 3,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
    },
    modules: [Autoplay],
});

const areaWeServeSwiper = new Swiper(".area-we-serve-swiper", {
    effect: "coverflow",
    grabCursor: true,
    coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
    },
    loop: true,
    autoplay: {
        delay: 2800,
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 15,
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 1,
            spaceBetween: 15,
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
    },
    modules: [Autoplay, EffectCoverflow],
});

// Home Page Slider
new Swiper(".home-slider", {
    loop: true,
    speed: 1000,
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
        delay: 10000,
        disableOnInteraction: false,
    },
    slidesPerView: 1,

    modules: [Autoplay],
});
